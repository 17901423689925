.item{
    
    display: inline-block;
    padding: 10px;
    margin: 15px;
    width: 200px;
    vertical-align: top;
}
.item .image img{
    height: 330px;
}

.item .title{
    font-weight: bold;
    font-size: 18px;
    height: 60px;
}

.item .actions{
    width: 100%;
    border-top: solid 1px #bbb;
    padding: 5px 0;
    margin-top: 10px;
}

.item .actions button{
    border: 0;
    border-radius: 3px;
    background-color:rgb(232, 78, 78);
    color: white;
    font-weight: bold;
    padding: 5px;
    width: 100%;
}